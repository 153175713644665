@import "typeface-barlow";
@import "typeface-barlow-semi-condensed";
@import "typeface-oswald";

:root {
    --dark-yellow-color: #FFD600;
    color-scheme: var(--color-scheme);
}

/* These may be overwritten by club pages */
/* When renaming any of these, or adding new, update clubColorUtils::computeColorCssVariables function */
:root {
    --color-scheme: dark;
    /* Should match color-scheme */
    --bg-color: #050C27;
    /* White if dark, black if light */
    --text-color: #FFFFFF;
    /* Light grey on dark themes, dark grey on light themes */
    --secondary-text-color: #B2B2B2;
    /* This should be either white or light grey. Will always have black font on top */
    --secondary-bg-color: #FFFFFF;
    /* Light grey or dark grey, depending on light/dark mode (usage: relevant match section) */
    --tertiary-bg-color: #262626;
    /* Generally the clubs primary color */
    --header-footer-color: #050C27;
    /* White or black, depending on header color */
    --header-footer-text-color: #FFFFFF;
    /* Either black or primary color, if primary color is very dark */
    --image-gradient-color: #050C27;
    /* Something that works on top of the backround color, used as line separator */
    --separator-color: #12326E;

    /* Most accenting. Anything on top of images, video or dynamic unknown content.
       A bright popping color that must work OK on dark and whites alike. Most of the time yellow */
    --accent-color: var(--dark-yellow-color);
    /* Some club specific accenting that happens on top of background-color. Such as links */
    --bg-accent-color: var(--dark-yellow-color);
    /* Any accenting that happens in the header or footer */
    --header-footer-accent-color: var(--dark-yellow-color);
    /* Color usage: league selection in "akutella matcher" */
    --option-selection-color: var(--accent-color);
}

html, body, #root {
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 16px;
}

body {
    margin: 0;
    font-family: "Barlow", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bg-color);
    color: var(--text-color);
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

button {
    color: var(--text-color);
    background: none;
}

a {
    text-decoration: none;
    color: var(--bg-accent-color);
}

a span {
    text-decoration: inherit;
}

* {
    box-sizing: border-box;
}

.scrollable {
    overflow-y: auto;
}

.scrollable::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}

.scrollable::-webkit-scrollbar-track {
    background: transparent;
}

.scrollable::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.5);
}

.scrollable::-webkit-scrollbar-thumb {
    min-height: 20px;
}

.scrollable::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0,0,0,0.75);
}

.flex {
    display: flex;
}

.flex-grow {
    flex-grow: 1;
}

ul {
    list-style: none;
    padding-inline: 0;
    margin: 0;
}

.clickable {
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.flex-vertically-centered {
    display: flex;
    flex: 1;
    min-height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.page-title {
    font-size: 65px;
    font-weight: 300;
    letter-spacing: -1px;
    margin: 16px 0 24px 0;
}

span.inline-block {
    display: inline-block;
}

.live-circle-icon {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: white;
    animation: blinking 3s linear infinite;
}

.live-circle-icon.small {
    height: 7px;
    width: 7px;
}

@keyframes blinking {
    50% {
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
}

/* --------------------- CUSTOM CHECKBOX ------------------- */

/* checkbox input container */
.check-box-cont {
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide default checkbox */
.check-box-cont input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
/* custom checkbox */
.check-box {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: 1px solid var(--text-color);
    border-radius: 3px;
}
  
/* background color when checked */
.check-box-cont input:checked ~ .check-box {
    background-color: var(--accent-color);
    border-color: var(--accent-color);
}
  
/* Create check mark (hidden when unchecked) */
.check-box:after {
    content: "";
    position: absolute;
    display: none;
}
  
/* Show check mark when input checked */
.check-box-cont input:checked ~ .check-box:after {
    display: block;
}
  
/* check mark style */
.check-box-cont .check-box:after {
    left: 30%;
    top: 5%;
    width: 25%;
    height: 60%;
    border: solid var(--bg-color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.bold {
    font-weight: bold !important;
}
.uppercase {
    text-transform: uppercase;
}
.underline {
    text-decoration: underline;
}

/* --------------------- MEDIA QUERIES ------------------- */
@media (max-width: 768px) {
    .page-title {
        font-size: 40px;
        margin-bottom: 12px;
    }
}